import { Toast } from "antd-mobile"
import { OSSPolicy } from "../../model/ossPolicy"
import Sha256Encode from "./sha256Encode"
import { Channel } from "phoenix"

// 获取上传文件的签名
export const getOSSPolicy = (conversationChannel: Channel): Promise<OSSPolicy> => {
    return new Promise<OSSPolicy>((resolve) => {
        conversationChannel.push("oss-upload-policy", {})
            .receive("ok", (resp: OSSPolicy) => {
                resolve(resp)
            })
            .receive("error", () => {
                Toast.show({ icon: "fail", content: "暂时无法上传图片" })
            })
    })
}

// 上传文件冰返回地址
export const uploadFile = async (conversationChannel: Channel, file: File, path: string, addonsData: { [key: string]: any } = {}): Promise<string> => {
    const ossPolicy = await getOSSPolicy(conversationChannel);
    const file_content = await file.arrayBuffer()
    const file_name = await Sha256Encode(file_content)

    const file_url = [ossPolicy.base_path, path, file_name].join("/")
    var formData = new FormData()
    formData.append('key', file_url)
    formData.append('policy', ossPolicy.policy)
    formData.append('success_action_status', "200")
    formData.append('OSSAccessKeyId', ossPolicy.accessid)
    formData.append('Signature', ossPolicy.signature)
    formData.append('file', file)

    Object.keys(addonsData).forEach((key: string) => {
        formData.append(key, addonsData[key])
    })

    await fetch("https://oss.zhi-yuan.net", { method: "POST", body: formData, mode: "cors" })
    return `${ossPolicy.service_attchment_host}/api/oss/${file_url}`;
}