import { create } from "zustand";
import { Socket } from "phoenix"
import config from "../config";

type socketState = {
    socket?: Socket | null,
    init: (kid: string | null, payload: string | null) => Socket | null
};

const useSocketStore = create<socketState>((set, get) => ({
    socket: null,
    init: (kid: string | null, payload: string | null) => {
        if (!!kid && !!payload) {
            const socket = new Socket(config.SocketEndpoint("/customer-socket"), { params: { kid, payload } })
            socket.connect();
            socket.onError(error => {
                console.log(error)
            })
            socket.onClose(error => {
                // message.error({ content: "与服务器丢失连接", key: "socket-close" })
            })
            socket.onOpen(() => {
                // message.success({ content: "与服务器建立了连接", key: "socket-close", duration: 1 })
            })

            set(() => ({ socket }))
            return socket;
        }
        return null
    }
}));

export default useSocketStore;