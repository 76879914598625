import { useEffect } from 'react';
import './App.css';
import { useSearchParams } from 'react-router-dom';
import useSocketStore from './state/socketStore';
import useSessionStore from './state/sessionStore';
import { PageConversation } from './module.conversation/Page.Conversation';
import useSessionStorageState from 'use-session-storage-state'

type KidPayloadType = {
  kid: string | null,
  payload: string | null
}

function App() {
  const [searchParams] = useSearchParams();
  const { init, socket } = useSocketStore();
  const { joinSessionChannel } = useSessionStore()
  const [kidPayload, setKidPayload] = useSessionStorageState<KidPayloadType>("kid-payload", { defaultValue: { kid: null, payload: null } })

  var payload: string | null = searchParams.get("payload") ?? kidPayload.payload // ?? "eyJhbGciOiJFZERTQSJ9.eyJvd25lcl90eXBlIjoidXNlciIsIm93bmVyX2lkIjoiMiIsImV4dGVybmFsIjp7ImF2YXRhciI6Imh0dHBzOi8vYXBpLmxpZ2h0dGNtLmNvbS9hcGkvYXR0YWNoL2NvbW11bml0eS9hdmF0YXIvMTcyMjIzOTYzMjU2MiIsIm5pY2tuYW1lIjoi5ZOI5Y-N5Y-N5aSN5aSN5ZOIIn0sInJvb20iOiJ1c2VyOjEifQ.uzGucK5PwKrMIheuRUEx4DakgWpQ2G8VHJYQpXfKP8Z7n1vHwMEqDdFxN52A6dmipv-ta8SXGPVCtMgjhhm8CA";
  var kid: string | null = searchParams.get("kid") ?? kidPayload.kid //?? "zy:application::acupoint-test";
  setKidPayload({ kid, payload })

  // 建立连接
  useEffect(() => {
    var socket = init(kid, payload);
    return () => { socket?.disconnect() }
  }, [payload, kid])

  // 加入session频道
  useEffect(() => {
    if (!socket) return;
    var channel = joinSessionChannel(socket);
    return () => { !!channel && channel!.leave() }
  }, [socket])


  return (
    <div className="App">
      {!!socket ? <PageConversation></PageConversation> : <></>}
    </div >
  );
}

export default App;

