import { Toast } from "antd-mobile";
import { MessageContentDataShare, MessageStatus } from "../../../model/message";
import { messageProps } from "./_type";
import useSessionStore from "../../../state/sessionStore";
import { useEffect, useState } from "react";
import { ToastHandler } from "antd-mobile/es/components/toast";
import "./MessageShare.css"
declare var window: any;
export type MessageShareProps = {


} & messageProps<MessageContentDataShare>

export default function MessageShare({ message, ...props }: MessageShareProps) {
    const { tenant } = useSessionStore();
    const [sendingShow, setSendingShow] = useState<boolean>(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        let toastHandler: ToastHandler | undefined;
        if (message.status === MessageStatus.sending) {
            timer = setTimeout(() => {
                setSendingShow(true)
                toastHandler = Toast.show({ icon: "loading", content: "网络不是太好..." })
            }, 1000);
        } else {
            setSendingShow(false)
            toastHandler?.close()
        }

        return () => {
            clearTimeout(timer);
        }
    }, [message.status])

    const handleClick = () => {
        const metaDataRoutes = message.content.data.meta?.["routes"];
        const miniProgramRoute = metaDataRoutes?.["wechatMiniProgram"]
        // alert(`wx: ${!!window.wx}\n wx.miniProgram: ${!!window.wx?.miniProgram}\n navigateFunc: ${miniProgramRoute?.["navigateFunc"]}\n path: ${miniProgramRoute?.["path"]}\n func存在: ${!!window.wx?.miniProgram?.[miniProgramRoute?.["navigateFunc"]]} `)

        window.wx?.miniProgram?.getEnv((res: any) => {
            // 如果在小程序里
            if (res.miniprogram) {
                try {
                    window.wx.miniProgram[miniProgramRoute?.["navigateFunc"]]?.({
                        url: miniProgramRoute?.["path"],
                        success: () => { },
                        fail: () => { alert(`打开失败，请截图发给客服~ \n wx: ${!!window.wx}\n wx.miniProgram: ${!!window.wx?.miniProgram}\n navigateFunc: ${miniProgramRoute?.["navigateFunc"]}\n path: ${miniProgramRoute?.["path"]}\n func: ${!!window.wx?.miniProgram?.[miniProgramRoute?.["navigateFunc"]]} `) }
                    })
                } catch (error) {
                }
            } else {
                alert(`目前只能在小程序里打开~`)
            }
        })
    }

    const data = message.content.data

    return !!tenant ? <div className="conversation component message-share" onClick={handleClick}>
        <div className="card">
            <img src={data.image.url} height={48} width={48} ></img>

            <div className="content">
                <div className="title" style={{ fontWeight: data.title.fontWeight || 400, color: data.title.color || "#444" }}>{data.title.text}</div>
                <div className="summary" style={{ fontWeight: data.summary?.fontWeight || 400, color: data.summary?.color || "silver" }}>{data.summary?.text}</div>
            </div>
        </div>

    </div> : <></>
}