import dayjs from "dayjs";
import { Owner } from "./owner";

export enum MessageContentType {
    string,
    audio,
    video,
    image,
    system,
    file,
    share
}

export enum MessageStatus {
    sending,
    sent,
    received,
    read
}

export class Message<T extends MessageContentDataBlank> {
    id: string;
    conversationId: string;
    event: string;
    tenantMeta: string;
    content: MessageContent<T>
    owner: Owner
    insertedAt: dayjs.Dayjs
    status: MessageStatus = MessageStatus.sent

    static fromObject(payload: any) {
        return new this({
            id: payload["id"],
            conversationId: payload["conversation_id"],
            event: payload["event"],
            tenantMeta: payload["tenant_meta"],
            content: MessageContent.fromObject(payload["content"]),
            owner: Owner.fromObject(payload["owner"]),
            insertedAt: dayjs(payload["inserted_at"]),
            status: payload.status
        });
    }

    constructor(payload: { conversationId: string, event: string, tenantMeta: string, content: MessageContent<T>, owner: Owner, insertedAt?: dayjs.Dayjs, status?: MessageStatus, id?: string }) {
        this.id = payload["id"]!;
        this.conversationId = payload["conversationId"];
        this.event = payload["event"];
        this.tenantMeta = payload["tenantMeta"];
        this.owner = new Owner(payload["owner"]);
        this.content = payload["content"];
        this.insertedAt = payload["insertedAt"] ?? dayjs();
        this.status = payload.status ?? MessageStatus.sent
    }
}

export class MessageContent<T extends MessageContentDataBlank> {
    type: MessageContentType;
    text: string
    data: T;
    size?: number
    filename?: string
    mime?: string

    static fromObject(content: any) {
        var data = new MessageContentDataBlank()
        var type = MessageContentType.string;

        switch (content["type"]) {
            case "string":
                type = MessageContentType.string
                break;
            case "audio":
                type = MessageContentType.audio
                break;
            case "video":
                type = MessageContentType.video
                data = new MessageContentDataVideo(content["data"]);
                break;
            case "image":
                type = MessageContentType.image
                data = new MessageContentDataImage(content["data"]);
                break;
            case "system":
                type = MessageContentType.system
                break;
            case "file":
                type = MessageContentType.file
                break;
            case "share":
                type = MessageContentType.share
                data = new MessageContentDataShare(content["data"])
                break;
            default:
                type = MessageContentType.string
                break;
        }

        return new this({ text: content["text"], size: content.size, filename: content.filename, mime: content.mime, type, data });
    }

    constructor(content: { text: string, type: MessageContentType, size?: number, filename?: string, mime?: string, data: T }) {
        this.type = content["type"]
        this.text = content["text"];
        this.size = content["size"];
        this.filename = content["filename"];
        this.mime = content["mime"];
        this.text = content["text"];
        this.data = content["data"];
    }
}
export class MessageContentDataBlank {
}

export class MessageContentDataText extends MessageContentDataBlank {
    text: string;
    constructor(data: { text: string }) {
        super()
        this.text = data["text"];
    }

}

export class MessageContentDataVideo extends MessageContentDataBlank {
    url: string;
    constructor(data: { url: string }) {
        super()
        this.url = data["url"];
    }
}

export class MessageContentDataImage extends MessageContentDataBlank {
    height: number;
    width: number;
    url: string;
    constructor(data: { url: string, height: number, width: number }) {
        super()
        this.height = data["height"];
        this.width = data["width"];
        this.url = data["url"];
    }
}

export type MessageContentDataShareText = {
    text: string,
    fontWeight: number | null,
    color: string | null
}

export type MessageContentDataShareImage = {
    url: string
}

export type MessageContentDataShareButton = {
    text: string
}


export class MessageContentDataShare extends MessageContentDataBlank {
    image: MessageContentDataShareImage;
    meta: any;
    summary: MessageContentDataShareText | null;
    title: MessageContentDataShareText;
    button: MessageContentDataShareButton;

    constructor(data: any) {
        super()
        this.meta = data["meta"];
        this.image = data["image"];
        this.summary = data["summary"];
        this.title = data["title"];
        this.button = data["button"];
    }
}
