import { DotLoading, ImageViewer, SpinLoading } from "antd-mobile";
import { messageProps } from "./_type";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "./MessageImage.css"
import { MessageContentDataImage, MessageStatus } from "../../../model/message";
import useSessionStore from "../../../state/sessionStore";
import RGBAFromData from "../../functions/rgbaFromData";

export default function MessageImage(params: messageProps<MessageContentDataImage>) {
    const { tenant } = useSessionStore();
    let height: number = params.message.content.data.height ?? 200
    let width: number = params.message.content.data.width ?? 150

    if (width > 150) {
        height = height / width * 150
        width = 150
    }

    if (height > 200) {
        width = width / height * 200
        height = 200
    }

    const handleClickView = () => {
        ImageViewer.show({ image: params.message.content.data.url })
    }

    return <div className={`conversation component message-image`} onClick={handleClickView}>
        {params.message.status === MessageStatus.sending &&
            <div className="sending">
                <DotLoading style={{ color: RGBAFromData(tenant!.theme.primary) }} />
            </div>}

        <LazyLoadImage alt="" src={params.message.content.data.url} height={height} width={width} style={{ objectFit: "contain" }} />
    </div>
}