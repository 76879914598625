import { Owner } from "./owner"

export class Conversation {
    id: string
    tenantMeta: string
    key: string
    avatar: string | null
    nickname: string | null
    ownerType: string | null
    ownerId: string | null
    owner: Owner
    unreadCount: number

    constructor(payload: any) {
        this.id = payload["id"]
        this.tenantMeta = payload["tenant_meta"]
        this.key = payload["key"]
        this.avatar = payload["avatar"]
        this.nickname = payload["nickname"]
        this.ownerType = payload["owner_type"]
        this.ownerId = payload["owner_id"]
        this.owner = new Owner(payload["owner"]);
        this.unreadCount = payload["unread_count"]

    }
}