import dayjs from "dayjs"
import { Message, MessageContentDataBlank } from "../../../model/message"
import "./MessageDateTime.css"

type MessageDateTimeProps = {
    message: Message<MessageContentDataBlank>,
    lastMessage?: Message<MessageContentDataBlank>
}

export default function MessageDateTime({ message, lastMessage }: MessageDateTimeProps): JSX.Element | null {
    let format = "HH:mm"

    if (message.insertedAt.isBefore(dayjs().startOf("day"))
        && message.insertedAt.isAfter(dayjs().startOf("day").add(-1, "day"))) {
        format = "昨日 HH:mm"
    } else if (message.insertedAt.isAfter(dayjs().startOf("year"))) {
        format = "M月D日 HH:mm"
    } else {
        format = "YYYY年M月D日 HH:mm"
    }

    if (message.insertedAt.add(-5, "minutes").isAfter(lastMessage?.insertedAt)) {
        return <div className="conversation component message-datetime">
            <div className="text" >
                {message.insertedAt.format(format)}
            </div>
        </div>
    } else {
        return <div></div>
    }

}