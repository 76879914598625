import { FormEvent, useState } from "react";
import "./TextField.css"
import useConversationStore from "../conversationStore";
import { BsImageFill } from "react-icons/bs";
import useSessionStore from "../../state/sessionStore";
import RGBAFromData from "../functions/rgbaFromData";

export default function TextField(props: any) {
    const [text, setText] = useState<string>("")
    const { tenant, owner } = useSessionStore();
    const { conversationChannel, conversation, pushImageMessage, pushTextMessage } = useConversationStore();
    // 发送文本
    const handleSendText = async (e: FormEvent<Element>) => {
        e.preventDefault();
        e.stopPropagation();
        setText("")
        pushTextMessage(text, owner!)
    }

    // 发送图片
    const handleSendImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]!;

        pushImageMessage(file, owner!);

    }


    return conversation && <div className="convrersation component text-field">
        <form onSubmit={handleSendText} className="form" enterKeyHint="send">
            <label className="input-wrap" htmlFor="text">
                <input name="text" autoFocus={false} enterKeyHint="send" className="input" value={text} placeholder="请输入您要咨询的内容~" onChange={(e) => setText(e.target.value)} />
            </label>
            <label className="image-wrap" htmlFor="image" style={{ color: RGBAFromData(tenant!.theme.primary) }}>
                <BsImageFill className="icon" />
                <input autoFocus={false} accept="image/*" type="file" name="image" onChange={handleSendImage} />
            </label>
        </form>
    </div>
}