import React, { useRef, useLayoutEffect, useCallback } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

export function InfiniteScrollDesc({ loadMoreRender, loading, hasNextPage, onLoadMore, effect, children, ...props }: any) {

    const [infiniteRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore,
        rootMargin: '200px 0px 0px 0px',
        delayInMs: 200
    });

    const scrollableRootRef = useRef<React.ElementRef<'div'> | null>(null);
    const lastScrollDistanceToBottomRef = useRef<number>();

    useLayoutEffect(() => {
        const scrollableRoot = scrollableRootRef.current;
        const lastScrollDistanceToBottom =
            lastScrollDistanceToBottomRef.current ?? 0;
        if (scrollableRoot) {
            scrollableRoot.scrollTop =
                scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
        }
    }, [effect, rootRef]);


    const rootRefSetter = useCallback(
        (node: HTMLDivElement) => {
            rootRef(node);
            scrollableRootRef.current = node;
        },
        [rootRef],
    );

    const handleRootScroll = useCallback(() => {
        const rootNode = scrollableRootRef.current;
        if (rootNode) {
            const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
            lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
        }
    }, []);

    const showNoneHint = !hasNextPage && (scrollableRootRef?.current?.scrollHeight! > scrollableRootRef?.current?.clientHeight!)

    return <div {...props} ref={rootRefSetter} onScroll={handleRootScroll}>
        {showNoneHint &&
            <div ref={infiniteRef} className="load-more">
                {loadMoreRender}
            </div>}
        {children}
    </div>
}
