import { SpinLoading, Toast } from "antd-mobile";
import { MessageContentDataText, MessageStatus } from "../../../model/message";
import Bubble from "../Bubble";
import { messageProps } from "./_type";
import RGBAFromData from "../../functions/rgbaFromData";
import useSessionStore from "../../../state/sessionStore";
import { useEffect, useState } from "react";
import { ToastHandler } from "antd-mobile/es/components/toast";

export default function MessageText(props: messageProps<MessageContentDataText>) {
    const { tenant } = useSessionStore();
    const [sendingShow, setSendingShow] = useState<boolean>(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;
        let toastHandler: ToastHandler | undefined;
        if (props.message.status === MessageStatus.sending) {
            timer = setTimeout(() => {
                setSendingShow(true)
                toastHandler = Toast.show({ icon: "loading", content: "网络不是太好..." })
            }, 1000);
        } else {
            setSendingShow(false)
            toastHandler?.close()
        }

        return () => {
            clearTimeout(timer);
        }
    }, [props.message.status])

    return <Bubble {...props}>
        <span>{props.message.content.text}</span>
        {props.message.status === MessageStatus.sending && sendingShow &&
            <div className="sending" style={{ paddingLeft: 10 }}>
                <SpinLoading color={RGBAFromData(tenant!.theme.messageColor)} style={{ "--size": "16px" }} />
            </div>}
    </Bubble>
}