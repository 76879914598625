import { MessageContentDataBlank, MessageContentDataImage, MessageContentDataShare, MessageContentDataText, MessageContentDataVideo, MessageContentType, Message as ModelMessage } from "../../model/message";
import { messageProps } from "./message/_type";
import MessageImage from "./message/MessageImage";
import MessageText from "./message/MessageText";
import MessageVideo from "./message/MessageVideo";

import "./Message.css"
import Avatar from "./Avatar";
import useSessionStore from "../../state/sessionStore";
import MessageShare from "./message/MessageShare";

export function Message<T extends MessageContentDataBlank>(props: messageProps<T>) {

    let messageContent: JSX.Element = <></>;
    // 是否是自己发的信息
    const isSelf = props.message.owner.id === props.currenctOwner.id;

    const { tenant } = useSessionStore()

    const message = props.message as unknown;
    switch (props.message.content.type) {
        case MessageContentType.string:
            messageContent = MessageText({ ...props, message: message as ModelMessage<MessageContentDataText> });
            break;

        case MessageContentType.image:
            messageContent = MessageImage({ ...props, message: message as ModelMessage<MessageContentDataImage> });
            break;

        case MessageContentType.video:
            messageContent = MessageVideo({ ...props, message: message as ModelMessage<MessageContentDataVideo> });
            break;

        case MessageContentType.share:
            messageContent = MessageShare({ ...props, message: message as ModelMessage<MessageContentDataShare> });
            break;

        default:
            messageContent = MessageText({ ...props, message: message as ModelMessage<MessageContentDataText> });
            break;
    }


    return <div className={`conversation component message ${tenant?.profile.showNickname && "show-nickname"} ${props.isGroupFirst && "group-first"} ${props.isGroupLast && "group-last"}`} style={{ flexDirection: isSelf ? "row-reverse" : "row" }}>
        {
            tenant?.profile.showAvatar &&
            <div className="avatar">
                {
                    props.isGroupLast && <Avatar url={props.message.owner.avatar} />
                }
            </div>
        }
        <div className="message-and-nickname" style={{ alignItems: isSelf ? "end" : "start" }}>
            {
                tenant?.profile.showNickname && props.isGroupFirst && <div className="nickname">
                    {props.message.owner.nickname}
                </div>
            }

            <div className="message-content">
                {messageContent}
            </div>
        </div>

    </div>

}