export default class Color {
    red: number
    green: number
    blue: number
    alpha: number

    constructor(color: any) {
        this.red = color["red"]
        this.green = color["green"]
        this.blue = color["blue"]
        this.alpha = color["alpha"] / 255
    }
}