import { Button } from "antd-mobile";
import { sendingMessage } from '../../model/sendingMessage';
import RGBAFromData from "../functions/rgbaFromData";
import { Tenant } from "../../model/tenant";
import { IoCloseCircle } from "react-icons/io5";

export function SendingMessage({ sendingMessage, tenant, onSubmit, onClose }: { sendingMessage: sendingMessage, tenant: Tenant, onSubmit: (message: sendingMessage) => void, onClose: () => void }) {
    return <div className="sending">
        <div className="card">
            <img src={sendingMessage.image.url} height={48} width={48} ></img>

            <div className="content">
                <div className="title" style={{ fontWeight: sendingMessage.title.fontWeight || 400, color: sendingMessage.title.color || "#444" }}>{sendingMessage.title.text}</div>
                <div className="summary" style={{ fontWeight: sendingMessage.summary?.fontWeight || 400, color: sendingMessage.summary?.color || "silver" }}>{sendingMessage.summary?.text}</div>
            </div>
            <div className="action">
                <Button  onClick={onClose} className="close-Button" fill="none" style={{ color: RGBAFromData(tenant.theme.messageBackgroundColor) }}><IoCloseCircle /></Button>
                <Button size="mini" onClick={() => onSubmit(sendingMessage)} style={{ backgroundColor: RGBAFromData(tenant.theme.messageBackgroundColor), color: RGBAFromData(tenant.theme.messageColor) }}>{sendingMessage.button?.text || "发送"}</Button>
            </div>
        </div>

    </div>
}