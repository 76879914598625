import Color from "../../model/color";
import { MessageContentDataBlank } from "../../model/message";
import useSessionStore from "../../state/sessionStore"
import RGBAFromData from "../functions/rgbaFromData";
import "./Bubble.css"
import { messageProps } from "./message/_type";

export default function Bubble<T extends MessageContentDataBlank>(props: messageProps<T> & { children: any }) {
    const { tenant } = useSessionStore()

    const defaultBackgoundColor = new Color({ red: 66, green: 66, blue: 66, alpha: 255 })
    const defaultColor = new Color({ red: 255, green: 255, blue: 255, alpha: 255 })
    // 是否是自己发的信息
    const isSelf = props.message.owner.id === props.currenctOwner.id;
    const selfBackgroundColor = tenant?.theme.messageBackgroundColor || defaultBackgoundColor
    const otherBackgroundColor = tenant?.theme.otherMessageBackgroundColor || defaultBackgoundColor
    const backgroundColor = isSelf ? selfBackgroundColor : otherBackgroundColor;

    const selfColor = tenant?.theme.messageColor || defaultColor
    const otherColor = tenant?.theme.otherMessageColor || defaultColor
    const color = isSelf ? selfColor : otherColor;

    // const selfColor = tenant?.theme.messageBackgroundColor || defaultBackgoundColor


    return <div className="conversation component bubble" style={{
        backgroundColor: RGBAFromData(backgroundColor),
        color: RGBAFromData(color)
    }}>
        {props.children}
    </div>
}