export class Owner {
    type: string
    id: string
    avatar: string
    nickname: string
    constructor(payload: { type: string, id: string, avatar: string, nickname: string }) {
        this.type = payload["type"]
        this.id = payload["id"]
        this.avatar = payload["avatar"]
        this.nickname = payload["nickname"]
    }

    static fromObject(payload: any) {
        return new this({ type: payload.type, id: payload.id, avatar: payload.avatar, nickname: payload.nickname })
    }
}
