import { useEffect, useState } from "react";
import useConversationStore from "./conversationStore";
import useSessionStore from "../state/sessionStore";
import useSocketStore from "../state/socketStore";
import "./Page.Conversation.css"
import { Message } from "./component/Message";
import MessageDateTime from "./component/message/MessageDateTime";
import isGroupFirst from "./functions/isGroupFirst";
import isGroupLast from "./functions/isGroupLast";
import TextField from "./component/TextField";
import { DotLoading } from "antd-mobile";
import RGBAFromData from "./functions/rgbaFromData";
import { MessageContentDataBlank, Message as ModelMessage } from "../model/message";
import { useSearchParams } from "react-router-dom";
import { sendingMessage } from '../model/sendingMessage';
import { InfiniteScrollDesc } from "./component/InifiniteScrollDesc";
import { SendingMessage } from "./component/SendingMessage";


export function PageConversation() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { socket } = useSocketStore();
    const { room, owner, tenant } = useSessionStore()

    const [sendingMessage, setSendingMessage] = useState<sendingMessage | null>()

    useEffect(() => {
        try {
            setSendingMessage(JSON.parse(decodeURIComponent(searchParams.get("sendingMessage") || "null")))
        } catch (error) {

        }
        setSearchParams({})
    }, [])

    // 加入会话频道，用于获取解密的信息
    useEffect(() => {
        if (!socket || !room) return;
        var channel = joinConversationChannel(socket!, room!);

        return () => { !!channel && channel!.leave() }
    }, [socket, room])

    const { joinConversationChannel, loading, hasMessages: hasNextPage, messages, page, pageSize, handleLoadHistory, conversationChannel } = useConversationStore()


    const renderMessages = <T extends MessageContentDataBlank>(): Array<JSX.Element> => {
        let sortedMessages = Object.values<ModelMessage<T>>(messages)
            .sort((a, b) => a.insertedAt.isBefore(b.insertedAt) ? -1 : 1)

        return sortedMessages.map((m, index) => {
            const nextMessage = sortedMessages[index + 1]
            const lastMessage = sortedMessages[index - 1]

            return <div key={m.id}>
                <MessageDateTime message={m} lastMessage={lastMessage} />
                <Message currenctOwner={owner!} message={m}
                    isGroupFirst={isGroupFirst(m, lastMessage)}
                    isGroupLast={isGroupLast(m, nextMessage)} />
            </div>
        })
    }

    const handleSendSendingMessage = (sendingMessage: sendingMessage) => {
        setSendingMessage(null)
        switch (sendingMessage?.type) {
            case "share":
                conversationChannel?.push("message", sendingMessage)
                break;

            default:
                break;
        }
    }

    return tenant && <div className="page conversation" >
        <InfiniteScrollDesc loadMoreRender={hasNextPage ? <DotLoading color={RGBAFromData(tenant.theme.primary)} /> : <span style={{ color: "grey", fontSize: 16 }}>没有了～</span>}
            className="messages" loading={loading} hasNextPage={hasNextPage} onLoadMore={() => handleLoadHistory(page + 1, pageSize)} effect={messages} >
            {renderMessages()}
        </InfiniteScrollDesc>

        {sendingMessage && <SendingMessage sendingMessage={sendingMessage} tenant={tenant} onSubmit={handleSendSendingMessage} onClose={() => setSendingMessage(null)} />}
        <div className="footer">
            <TextField />
        </div>
    </div>


}
