import { create } from "zustand";
import { Channel, Socket } from "phoenix"
import { Owner } from "../model/owner";
import { Tenant } from "../model/tenant";

type sessionState = {
    sessionChannel: Channel | null,
    owner: Owner | null,
    tenant: Tenant | null,
    room: string | null,
    joinSessionChannel: (socket: Socket) => Channel
};

const useSessionStore = create<sessionState>((set, get) => ({
    sessionChannel: null,
    owner: null,
    tenant: null,
    room: null,
    joinSessionChannel: (socket: Socket) => {
        const channel: Channel = socket.channel("conversation:customer:session")

        channel.join().receive("ok", (resp) => {
            var owner = new Owner(resp.owner)
            var tenant = new Tenant(resp.tenant)
            var room = resp.room

            set(() => {
                return { sessionChannel: channel, owner, tenant, room };
            })
        })

        return channel;
    }
}));

export default useSessionStore;