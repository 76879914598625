import { create } from "zustand";
import { Channel, Socket } from "phoenix"
import { Conversation } from "../model/conversation";
import { Message, MessageContent, MessageContentDataBlank, MessageContentDataImage, MessageContentDataShare, MessageContentDataText, MessageContentType, MessageStatus } from "../model/message";
import { uploadFile } from "./functions/upload";
import { Toast } from "antd-mobile";
import { imageFileResizer } from "@peacechen/react-image-file-resizer";
import { imageSize } from "./functions/imageSize";
import dayjs from "dayjs";
import { Owner } from "../model/owner";
import { v4 as uuidv4 } from 'uuid';

type conversatinState = {
    conversationChannel: Channel | null,
    // 会话信息
    conversation: Conversation | null,
    // 消息列表
    messages: any,
    // 加载中
    loading: boolean,
    // 是否还有消息
    hasMessages: boolean,
    page: number,
    pageSize: number,
    joinConversationChannel: (socket: Socket, room: string) => Channel,
    handleLoadHistory: (page: number, size: number) => Promise<void>,
    pushImageMessage: (file: File, owner: Owner) => Promise<any>,
    pushTextMessage: (text: string, owner: Owner) => Promise<any>
};

const useConversationStore = create<conversatinState>((set, get) => {

    // 构造待发送的消息
    const sendingMessageBuild = <T extends MessageContentDataBlank>(owner: Owner, content: MessageContent<T>): Message<T> => {
        const { conversation } = get()
        const sendingMessage = new Message({
            id: uuidv4(), conversationId: conversation?.id!, event: "message", tenantMeta: conversation?.tenantMeta!, owner, insertedAt: dayjs(),
            status: MessageStatus.sending, content
        });

        set((state) => {
            return { messages: { ...state.messages, [sendingMessage.id]: sendingMessage } }
        });
        return sendingMessage;
    }

    return {
        conversationChannel: null,
        conversation: null,
        page: 1,
        pageSize: 20,
        loading: true,
        hasMessages: true,
        messages: {},
        joinConversationChannel: (socket: Socket, room: string) => {
            const channel: Channel = socket.channel(`conversation:customer:${room}`);
            const state = get();

            channel.join().receive("ok", (resp) => {
                var conversation = new Conversation(resp["conversation"])
                set(() => {
                    return { conversationChannel: channel, conversation };
                })

                state.handleLoadHistory(state.page, state.pageSize);
            })

            channel.on("message", resp => {
                set((state) => {
                    let newMessage = Message.fromObject(resp);
                    return { messages: { ...state.messages, [newMessage.id]: newMessage } };
                })

                channel.push("read", { message_id: resp.id })
            })

            channel.on("message-revoked", (resp) => {
                set((state) => {
                    let newMessage = Message.fromObject(resp);
                    return { messages: { ...state.messages, [newMessage.id]: newMessage } };
                })
            })

            return channel;
        },
        pushTextMessage: (text: string, owner: Owner) => {
            const { conversationChannel } = get()
            var message = sendingMessageBuild(owner, new MessageContent({
                type: MessageContentType.string,
                text, data: new MessageContentDataText({ text: text })
            }))

            // return new Promise((resolve, reject) => { })
            return new Promise((resolve, reject) => {
                conversationChannel?.push("message", { id: message.id, type: "text", content: message.content.text })
                    .receive("ok", (data) => {
                        resolve(data)
                    }).receive("error", (data) => {
                        reject(data)
                    })
            });
        },
        pushImageMessage: async (file: File, owner: Owner) => {
            if (file == null)
                return Toast.show({ icon: 'fail', content: '请选择一个图片' })

            let image: any = await imageFileResizer({
                compressFormat: "webp",
                file: file,
                maxHeight: 1000,
                maxWidth: 1000,
                minHeight: 30,
                minWidth: 30,
                outputType: "file",
                quality: 70,
            });

            const { conversationChannel, conversation } = get()

            // 解码图片获取base64和宽高
            const imageObject = await imageSize(image!)

            var message = sendingMessageBuild(owner, new MessageContent({
                type: MessageContentType.image,
                text: "[图片]",
                filename: file.name, size: image.size, mime: image.type,
                data: new MessageContentDataImage({ url: imageObject.src, width: imageObject.width, height: imageObject.height }),
            }))

            var url = await uploadFile(conversationChannel!, image!, conversation!.key)

            return new Promise((resolve, reject) => {
                get().conversationChannel?.push("message", {
                    id: message.id, url, type: "image", filename: message.content.filename, size: message.content.size, mime: message.content.mime,
                    data: { height: imageObject.height, width: imageObject.width }
                }).receive("ok", (data) => {
                    resolve(data)
                }).receive("error", (data) => {
                    reject(data)
                })
            })
        },
        handleLoadHistory: (page: number, size: number) => {
            set(() => ({ loading: true }))
            return new Promise((resolve) => {
                get().conversationChannel?.push("history", { page, size }).receive("ok", ({ data: data }: { data: any[] }) => {
                    set((state) => {
                        data.forEach((m) => {
                            let newMessage = Message.fromObject(m);

                            state.messages[newMessage.id] = newMessage;
                        })

                        return {
                            messages: { ...state.messages },
                            loading: false, hasMessages: data.length >= size,
                            page,
                            pageSize: size
                        }
                    })
                    resolve()
                })
            })
        }
    }
});


export default useConversationStore;