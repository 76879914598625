declare var window: any;

window.ENDPOINT = process.env.REACT_APP_ENDPOINT || "cs.dev.zhi-yuan.net";
window.SCHEMA = process.env.REACT_APP_SCHEMA || "https"
window.WEBSOCKET_SCHEMA = process.env.REACT_APP_WEBSOCKET_SCHEMA || "wss"

const config = {
    HttpEndpoint: (path: any) => `${window.SCHEMA}://${window.ENDPOINT}${path}`,
    SocketEndpoint: (path: any) => `${window.WEBSOCKET_SCHEMA}://${window.ENDPOINT}${path}`
}

export default config