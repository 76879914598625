import Color from "./color";

export class Tenant {
    meta: string;
    name: string;
    icon: string;
    theme: TenantTheme;
    profile: TenantProfile;
    constructor(payload: any) {
        this.meta = payload["meta"]
        this.name = payload["name"]
        this.icon = payload["icon"]
        this.theme = new TenantTheme(payload["theme"])
        this.profile = new TenantProfile(payload["profile"])
    }
}

export class TenantTheme {
    primary: Color;
    messageBackgroundColor: Color;
    messageColor: Color;
    otherMessageColor: Color;
    otherMessageBackgroundColor: Color;
    constructor(theme: any) {
        this.primary = new Color(theme["primary"])
        this.messageBackgroundColor = new Color(theme["messageBackgroundColor"])
        this.messageColor = new Color(theme["messageColor"])
        this.otherMessageColor = new Color(theme["otherMessageColor"])
        this.otherMessageBackgroundColor = new Color(theme["otherMessageBackgroundColor"])
    }
}

export class TenantProfile {
    showAvatar?: boolean
    showNickname?: boolean
    constructor(profile: any) {
        this.showAvatar = profile["show_avatar"]
        this.showNickname = profile["show_nickname"]
    }
}