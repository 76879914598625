import { LegacyRef, useRef, useState } from "react";
import { messageProps } from "./_type";

import "./MessageVideo.css"
import { MessageContentDataVideo } from "../../../model/message";

export default function MessageVideo(params: messageProps<MessageContentDataVideo>) {
    const [height, setHeight] = useState<number>(400)
    const [width, setWidth] = useState<number>(300)
    const ref = useRef<HTMLVideoElement>(null)

    const handleLoaded = () => {
        let height = ref.current?.videoHeight || 400;
        let width = ref.current?.videoWidth || 300;

        if (width > 300) {
            height = height / width * 300
            width = 300
        }

        if (height > 400) {
            width = width / height * 400
            height = 400
        }
        setHeight(height)
        setWidth(width)
    }

    return <div className="conversation component message-video" style={{ height, width }}>
        <video ref={ref} controls onLoadedMetadata={handleLoaded}>
            <source src={params.message.content.data.url} />
        </video>
    </div>
}